/* eslint-disable max-len */
import { Labels } from '../../shared-components/global/labels.js';

Labels.set('ui-signing', 'TITLE', 'Skaitmeninis pasirašymas');
Labels.set('ui-signing', 'DIGIPASS_CHALLENGE', 'Kodas įvedimui į generatorių:');
Labels.set(
    'ui-signing',
    'DIGIPASS_PASSWORD',
    'Slaptažodis iš kodų generatoriaus:'
);
Labels.set('ui-signing', 'DIGIPASS_PLACEHOLDER', 'APPLI 2');
Labels.set('ui-signing', 'DIGIPASS_CONFIRM', 'Patvirtinti');
Labels.set(
    'ui-signing',
    'DIGIPASS_ERROR_MESSAGE',
    'Nepavyko pasirašyti su PIN kodų generatoriumi.'
);

Labels.set(
    'ui-signing',
    'SMART_ID_RUNNING',
    'Užklausa išsiųstą į jūsų Smart-ID išmaniąją programėlę. Patikrinkite kodą.'
);
Labels.set('ui-signing', 'SMART_ID_CHALLENGE', 'Jūsų kontrolinis kodas:');
Labels.set(
    'ui-signing',
    'SMART_ID_INTRO_MESSAGE',
    'Įsitikinkite, kad sutinkate su pasirašoma informacija. Peržiūrėti pasirašomą dokumentą galite grįžę į ankstesnį langą.'
);
Labels.set('ui-signing', 'SMART_ID_EXPLANATION', '');
Labels.set(
    'ui-signing',
    'SMART_ID_SENDING_MESSAGE',
    'Užklausa išsiųsta į jūsų išmanųjį įrenginį. Patikrinkite kontrolinį kodą ir įveskite PIN2.'
);

Labels.set(
    'ui-signing',
    'MOBILE_ID_RUNNING',
    'Patvirtinkite su Mobile-ID. Būtinai palyginkite rodomą kodą!'
);
Labels.set('ui-signing', 'MOBILE_ID_CHALLENGE', 'Jūsų kontrolinis kodas:');
Labels.set(
    'ui-signing',
    'MOBILE_ID_INTRO_MESSAGE',
    'Įsitikinkite, kad sutinkate su pasirašoma informacija. Peržiūrėti pasirašomą dokumentą galite grįžę į ankstesnį langą.'
);
Labels.set('ui-signing', 'MOBILE_ID_EXPLANATION', '');
Labels.set(
    'ui-signing',
    'MOBILE_ID_SENDING_MESSAGE',
    'Užklausa išsiųsta į jūsų išmanųjį įrenginį. Patikrinkite kontrolinį kodą ir įveskite PIN2.'
);

Labels.set(
    'ui-signing',
    'EPARAKSTS_MOBILE_RUNNING',
    'Užklausa išsiųstą į jūsų eParaksts mobile išmaniąją programėlę. Patikrinkite kodą.'
);
Labels.set(
    'ui-signing',
    'EPARAKSTS_MOBILE_CHALLENGE',
    'Jūsų kontrolinis kodas:'
);
Labels.set(
    'ui-signing',
    'EPARAKSTS_MOBILE_INTRO_MESSAGE',
    'Įsitikinkite, kad sutinkate su pasirašoma informacija. Peržiūrėti pasirašomą dokumentą galite grįžę į ankstesnį langą.'
);
Labels.set('ui-signing', 'EPARAKSTS_MOBILE_EXPLANATION', '');
Labels.set(
    'ui-signing',
    'EPARAKSTS_MOBILE_SENDING_MESSAGE',
    'Užklausa išsiųsta į jūsų išmanųjį įrenginį. Patikrinkite kontrolinį kodą ir įveskite PIN2.'
);
Labels.set(
    'ui-signing',
    'EPARAKSTS_MOBILE_ERROR_MESSAGE',
    'Nepavyko pasirašyti su eParaksts mobile.'
);

Labels.set(
    'ui-signing',
    'SIMPLE_ID_RUNNING',
    'Patvirtinkite biometriniais duomenimis arba PIN kodu. Būtinai palyginkite rodomą kodą!'
);
Labels.set('ui-signing', 'SIMPLE_ID_CHALLENGE', 'Jūsų kontrolinis kodas:');
Labels.set(
    'ui-signing',
    'SIMPLE_ID_INTRO_MESSAGE',
    'Įsitikinkite, kad suinkate su pasirašoma informacija. Peržiūrėti pasirašomą dokumentą galite grįžę į ankstesnį langą.'
);
Labels.set('ui-signing', 'SIMPLE_ID_EXPLANATION', '');
Labels.set(
    'ui-signing',
    'SIMPLE_ID_SENDING_MESSAGE',
    'Užklausa išsiųsta į jūsų išmanųjį įrenginį. Patikrinkite kontrolinį kodą ir nuskenuokite savo biometrinius duomenis arba įveskite PIN kodą.'
);

Labels.set('ui-signing', 'CANCEL_BUTTON_LABEL', 'Atgal');
Labels.set('ui-signing', 'CONFIRM_BUTTON_LABEL', 'Patvirtinti');

Labels.set(
    'ui-signing',
    'SMART_ID_ERROR_MESSAGE',
    'Nepavyko pasirašyti su Smart-ID.'
);
Labels.set(
    'ui-signing',
    'MOBILE_ID_ERROR_MESSAGE',
    'Nepavyko pasirašyti su Mobile-ID.'
);
Labels.set(
    'ui-signing',
    'SIMPLE_ID_ERROR_MESSAGE',
    'Nepavyko pasirašyti biometriniu būdu arba PIN kodu.'
);
Labels.set(
    'ui-signing',
    'CERTIFICATE_ERROR_MESSAGE',
    'Nepavyko pasirašyti su ID kortele.'
);

Labels.set(
    'ui-signing',
    'SMART_ID_BUTTON_LABEL',
    'Pasirašyti Smart-ID PIN2 kodu'
);
Labels.set(
    'ui-signing',
    'MOBILE_ID_BUTTON_LABEL',
    'Pasirašyti Mobile-ID PIN2 kodu'
);
Labels.set(
    'ui-signing',
    'EPARAKSTS_MOBILE_BUTTON_LABEL',
    'Pasirašyti eParaksts mobile PIN2 kodu.'
);
Labels.set(
    'ui-signing',
    'SIMPLE_ID_BUTTON_LABEL',
    'Patvirtinti biometriniais duomenimis arba PIN kodu'
);
Labels.set(
    'ui-signing',
    'CERTIFICATE_BUTTON_LABEL',
    'Pasirašyti ID kortelės PIN2 kodu'
);
Labels.set('ui-signing', 'DIGIPASS_BUTTON_LABEL', 'Patvirtinti');

Labels.set(
    'ui-signing',
    'HWCRYPTO_DEFAULT_ERROR',
    'Neveikia ID kortelės plėtinys. Perkraukite naršyklę ir bandykite iš naujo. {{info-link}}'
);
Labels.set(
    'ui-signing',
    'HWCRYPTO_NO_CERTIFICATES',
    'Nėra galiojančio ID kortelės sertifikato. Išimkite kortelę iš skaitytuvo, įdėkite vėl ir bandykite dar kartą. {{info-link}}'
);
Labels.set(
    'ui-signing',
    'HWCRYPTO_USER_CANCEL',
    'Patvirtinimas buvo nutrauktas, nes nuspaudėte mygtuką „Atšaukti“.'
);
Labels.set(
    'ui-signing',
    'HWCRYPTO_DRIVER_ERROR',
    'Įvyko ID kortelės programinės įrangos klaida. Patikrinkite, ar ID kortelės nuskaitymo programa atnaujinta. Jei ne - atnaujinkite ją, perkraukite naršyklę ir bandykite dar kartą. {{info-link}}'
);
Labels.set(
    'ui-signing',
    'HWCRYPTO_NO_IMPLEMENTATION',
    'Patikrinkite, ar ID kortelės nuskaitymo programa atnaujinta. Įvyko ID kortelės plėtinio klaida. Savo naršyklėje aktyvuokite įskiepį „Web eID" arba „eParaksts signing extension" tada perkraukite naršyklę ir bandykite iš naujo.'
);

Labels.set('ui-signing', 'LINK_PLACEHOLDER', 'Plačiau apie ID kortelę');
/* eslint-enable max-len */
